<template>
  <v-container fluid>
    <v-card tile>
      <div class="pl-6">
        <p class="text-body-1 font-weight-regular pt-4">
          Total documentos pendientes:
          {{ totaDocumentosPendientes }}
        </p>
        <p class="text-caption text--secondary font-weight-bold mt-n3 pb-4">
          <v-icon class="" color="black">mdi-calendar-range</v-icon>
          {{ fechaSelect }}
        </p>
      </div>
    </v-card>

    <v-row>
      <!-- Recuadro para la tabla -->
      <v-col cols="10">
        <v-data-table
          :headers="headers"
          :items="datosTabla"
          item-key="name"
          class="elevation-2"
        >
          <!-- verificamos si tiene un fiador -->
          <template v-slot:[`item.fiador_nombre_completo`]="{ item }">
            <span v-if="!item.fiador_nombre_completo">-</span>
            <span v-if="item.fiador_nombre_completo">{{ item.fiador_nombre_completo }}</span>
          </template>

          <!-- para el botón de VER -->
          <template v-slot:[`item.ver`]="{ item }">
            <v-btn x-small dark color="#031D6A" @click="fnVerRegistro(item)"
              >VER</v-btn
            >
          </template>
        </v-data-table>
      </v-col>

      <!-- recuadro para los parámetros de búsqueda -->
      <v-col cols="2">
        <v-card tile>
          <div class="pt-2">
            <p class="text-body-2 font-weight-medium text--secondary pl-4">
              Parámetros de búsqueda
            </p>

            <v-card-text class="mt-n6">
              <v-menu
                ref="fechaDialog"
                v-model="modalFechas"
                :close-on-content-click="false"
                :nudge-right="-17"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="selectedFecha"
                    label="Días"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :selected-items-text="'$vuetify.datePicker.itemsSelected'"
                  locale="es"
                  width="240"
                  no-title
                  scrollable
                  v-model="dialogVarSelectedFecha"
                  range
                  :max="disabledFecha"
                  color="primary"
                  header-color="primary"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modalFechas = false">
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.fechaDialog.save(dialogVarSelectedFecha)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-text-field
                v-model="nombre"
                label="Nombre"
                prepend-icon="mdi-database-search"
                class="mt-n2"
                dense
              ></v-text-field>

              <v-text-field
                v-model="documentoIdentificacion"
                label="Documento identificación"
                prepend-icon="mdi-database-search"
                dense
              ></v-text-field>

              <v-select
                v-model="perfilPersona"
                :items="itemsPerfilPersona"
                label="Perfil persona"
                prepend-icon="mdi-database-search"
                dense
              ></v-select>

              <v-select
                v-model="resultadoEvaluacion"
                :items="itemsResultadoEvaluacion"
                label="Resultado evaluación"
                prepend-icon="mdi-database-search"
                dense
              ></v-select>

              <v-select
                v-model="usuarios"
                :items="itemsUsuarios"
                label="Agente de comercio"
                prepend-icon="mdi-database-search"
                dense
              ></v-select>

              <v-select
                v-model="sector"
                :items="itemsSector"
                label="Sector"
                prepend-icon="mdi-database-search"
                dense
              ></v-select>

              <v-select
                v-model="ruta"
                :items="itemsRuta"
                label="Ruta"
                prepend-icon="mdi-database-search"
                dense
              ></v-select>
            </v-card-text>
          </div>
          <v-card-actions class="justify-end pb-6 mt-n4">
            <v-btn
              small
              color="#031D6A"
              class="white--text mr-1"
              @click="fnAplicarParametros()"
            >
              APLICAR PARÁMETROS
              <v-icon small class="ml-2">mdi-magnify</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- para el recuadro que indica que se finalizó exitosamente la revisión -->
    <v-snackbar
      v-model="snackbar"
      timeout="3500"
      color="success"
      right
      top
      class="mt-14"
    >
      <v-row>
        <v-col cols="1" md="1">
          <v-icon>mdi-check-circle</v-icon>
        </v-col>
        <v-col cols="11" md="11">
          <div class="text-center">
            <p class="text-subtitle-2 font-weight-medium white--text">
              Loremipsum
            </p>
            <p
              class="text-subtitle-2 font-weight-medium white--text mt-n4 mb-n1"
            >
              para la evaluación #{{ numeroEvaluacion }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-snackbar>
  </v-container>
</template>

<script>
import moment from 'moment';
require('moment/locale/es.js');

export default {
  name: 'documentosPendientesConCompromisoDePago',
  data() {
    return {
      //variables para la info de la ventana verde superior derecha, cuando se finalizó una revisión exitosa
      snackbar: false,
      numeroEvaluacion: 33256,

      totaDocumentosPendientes: 20, //variable temporal para mostrar el número total de precalificaciones completadas que se muestra en la card superior
      fechaSelect: '', // fecha de prueba que se muestra en la card superior

      //encabezados para la tabla
      headers: [
        {
          text: 'Número evaluación',
          align: 'start',
          value: 'evaluacion',
          sortable: false,
        },
        {
          text: 'Código SAP',
          align: 'start',
          value: 'codigo_sap',
          sortable: true,
          width: 80
        },
        {
          text: 'Nombre persona',
          align: 'start',
          value: 'persona',
          sortable: false,
          width: 170,
        },
        {
          text: 'Fecha de nacimiento',
          align: 'start',
          value: 'fecha_de_nacimiento',
          sortable: false,
          width: 150,
          type: 'date'
        },
        {
          text: 'NIT',
          align: 'start',
          value: 'nit',
          sortable: false,
        },
        {
          text: 'Documento identificación',
          align: 'start',
          value: 'numeroId',
          sortable: false,
        },
        {
          text: 'Tipo cliente',
          align: 'start',
          value: 'buro_tipo_de_usuario',
          sortable: false,
        },
        { text: 'Fiador', align: 'center', value: 'fiador_nombre_completo', sortable: false },
        {
          text: 'Resultado evaluación',
          align: 'start',
          value: 'resultado',
          sortable: false,
        },
        {
          text: 'Agente Comercio',
          align: 'start',
          value: 'CREADO_POR_USUARIO',
          sortable: false,
          width: 100,
        },
        {
          text: 'Fecha campaña',
          align: 'start',
          value: 'fecha_campania',
          sortable: false,
          type: 'date'
        },
        { text: 'Sector', align: 'start', value: 'sector', sortable: false },
        { text: 'Ver', align: 'center', value: 'ver', sortable: false },
      ],

      //datos para mostrar en la tabla (se tiene un dato de prueba)
      datosTabla: [
        {
          evaluacion: 33256,
          persona: 'Luisa López Pérez',
          numeroId: '231620690304',
          sector: '95',
          ruta: 'EMP095',
          contado: 'Si',
          fechaCampania: '15/10/2023',
          codigoReferencia: 33321241,
          perfil: 'Deudor',
          fiador: '',
          resultado: 'VERDE',
          agente: 'sector95',
        },
        {
          evaluacion: 33256,
          persona: 'Luisa López Pérez',
          numeroId: '231620690304',
          sector: '95',
          ruta: 'EMP095',
          contado: 'Si',
          fechaCampania: '15/10/2023',
          codigoReferencia: 33321241,
          perfil: 'Deudor',
          fiador: '',
          resultado: 'VERDE',
          agente: 'sector95',
        },
      ],

      //--------------variables para el cuadro de búsqueda

      //variables para la fecha
      modalFechas: false,
      dialogVarSelectedFecha: null,
      selectedFecha: [
        moment().startOf('month').format('DD-MM-YYYY'),
        moment().format('DD-MM-YYYY'),
      ], //en esta variable es donde se almacenará la fecha o el rango de fechas
      disabledFecha: moment().format('YYYY-MM-DD'),

      nombre: null, //almacenamos el nombre para los parámetros de búsqueda
      documentoIdentificacion: null,
      perfilPersona: null,
      resultadoEvaluacion: null,
      usuarios: null,
      sector: null,
      ruta: null,
      //Valores con valores temporales
      itemsPerfilPersona: ['Deudor', 'random', 'otro'],
      itemsResultadoEvaluacion: ['VERDE', 'ROJO', 'AMARILLO'],
      itemsUsuarios: ['user1', 'user2', 'user3'],
      itemsSector: ['95', '56', '88'],
      itemsRuta: ['EMP095', 'SER765', 'RTG123'],

      detalleEvaluacion: {}, //para mostrar la info en la ventana de dialogo
    };
  },

  methods: {
    fnVerRegistro(data) {
      console.log(data);

      this.$router.push(
        '/documentos-pendientes-revision'
      ); //redirigimos a la página para verificar las revisiones que tenga pendientes
    },

    //funcion para recopilar toda la información cuando se da clic en el botón de aplicar parámetros (en la card de búsqueda)
    fnAplicarParametros() {
      console.log('aplicanco parámetros de búsqueda');
      this.page = 1;

      const parametros = {
        dias: this.selectedFecha,
        nombre: this.nombre,
        documentoId: this.documentoIdentificacion,
        perfilP: this.perfilPersona,
        resultadoE: this.resultadoEvaluacion,
        usuarios: this.usuarios,
        sector: this.sector,
        ruta: this.ruta,
      };
      console.log(parametros);
    },

    //funcion para mostrar el recuadro verde la esquina superior derecha que indica que se finalizó la revisión
    fnFinalizacionRevision() {
      const revision = localStorage.getItem('revision');
      if (revision) {
        console.log('se terminó una revisión de compromiso de pago');
        this.snackbar = true;

        this.datosTabla = []; //Se borran los datos de la tabla, como ejemplo nada mas
      } else {
        console.log('no se revisó nada ');
      }

      localStorage.removeItem('revision'); //eliminamos la info almacenada de revisión
    },
  },

  watch: {
    /** parametrización por fechas con el calendario*/
    dialogVarSelectedFecha(newValue) {
      this.selectedFecha = [
        moment(newValue[0]).format('DD-MM-YYYY'),
        moment(newValue[1]).format('DD-MM-YYYY'),
      ];
    },
  },

  //para verificar si se aprobó un compromiso de pago(únicamente para pruebas)
  created() {
    this.fnFinalizacionRevision();
  },
};
</script>

<style scoped></style>
